.sign-in-content-container {
    text-align: center; 
	width: 80%;
	height: 10rem;	
	position: absolute;
	top:0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
}

.space {
	margin: 0.5rem;
}

.sign-in-button {
	line-height: normal
}