.taskModule {
    width: 80rem;

    .formContainer {
        position: absolute;
        top: 0rem;
        bottom: 8.4rem;
        width: 100%;

        .scrollableContent {
            overflow: auto;
            height: 100%;
            min-height: 100%;
            margin-inline-start: 3.2rem;

            .formContentContainer {
                margin-inline-end: 3.5rem;

                .contentField {

                    h3 {
                        margin: 2rem 0rem 0rem 0rem;
                    }

                    ul {
                        margin: 0rem -1rem;

                        li {
                            height: 1.25rem !important;

                            img {
                                width: 2.5rem;
                                height: 2.5rem;
                            }
                        }
                    }
                }
            }

            .adaptiveCardContainer {
                margin: 0 2rem;
            }
        }

        .displayMessageField {
            color: #6264A7;
            margin: 0rem 3.2rem;

            h3 {
                margin: 2rem 0rem 0rem 0rem;
            }

            ul {
                margin: 0rem -1rem;

                li {
                    height: 1.25rem !important;

                    img {
                        width: 2.5rem;
                        height: 2.5rem;
                    }
                }
            }

            .iconStyle {
                float: left;
                padding-right: 1em;
            }

            .light {
                font-weight: lighter;
            }
        }

        .footerContainer {
            position: fixed;
            bottom: 0;
            left: 0;
            padding: 2rem 0rem 3.2rem 0rem;
            width: 100%;

            .hide {
                display: none;
            }

            .hiddenLoader {
                display: none;
            }

            .sendingLoader {
                width: 18rem;
                margin-right: 2rem;
                height: 3rem;
            }

            .buttonContainer {
                margin-inline-end: 3.2rem;

                #saveBtn {
                    margin-inline-start: 0.8rem;
                }
            }
        }
    }
}
