.teams-card {
    border: 0.2rem solid #F1F0EF;
    border-top-color: #ACCEEF;
    border-radius: 0.4rem;
}

a.ac-anchor {
    text-decoration: none;
}

a.ac-anchor:link {
    color: #6264A7;
}

a.ac-anchor:visited {
    color: #6264A7;
}

a.ac-anchor:link:active {
    color: #6264A7;
}

a.ac-anchor:visited:active {
    color: #6264A7;
}

.ac-container.ac-selectable,
.ac-columnSet.ac-selectable {
    padding: 0rem;
}

.ac-container.ac-selectable:hover,
.ac-columnSet.ac-selectable:hover {
    background-color: rgba(0, 0, 0, 0.1) !important;
}

.ac-container.ac-selectable:active,
.ac-columnSet.ac-selectable:active {
    background-color: rgba(0, 0, 0, 0.15) !important;
}

.ac-pushButton {
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    vertical-align: middle;
    cursor: default;
    font-family: "Segoe UI", sans-serif;
    font-size: 1.4rem;
    padding: 0.4rem 1rem 0.5rem 1rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    height: 3.2rem;
    background-color: white;
    color: #6264A7;
    border: 0.2rem solid #BFC0DA;
    border-radius: 0.4rem;
}

.ac-pushButton:hover {
    background-color: #6264A7;
    border: 0.2rem solid #6264A7;
    color: white;
}

.ac-pushButton:active {
    background-color: #6264A7;
    border: 0.2rem solid #6264A7;
    color: white;
}

.ac-pushButton.expanded {
    background-color: #6264A7;
    border: 0.2rem solid #6264A7;
    color: white;
}

.ac-pushButton.style-emphasis {
    background-color: #6264A7;
    border: 0.2rem solid #6264A7;
    color: white;
}

.ac-input {
    font-family: "Segoe UI", sans-serif;
    font-size: 1.4rem;
    color: black;
}

.ac-input.ac-input-required {
    background-image: linear-gradient(45deg, transparent, transparent 50%, red 50%, red 100%);
    background-position: top right;
    background-size: .05em .05em;
    background-repeat: no-repeat;
}

.ac-input.ac-input-validation-failed {
    border: 0.1rem solid red !important;
}

.ac-input.ac-textInput {
    resize: none;
}

.ac-input.ac-textInput.ac-multiline {
    height: 7.2rem;
}

.ac-input.ac-textInput,
.ac-input.ac-numberInput,
.ac-input.ac-dateInput,
.ac-input.ac-timeInput {
    height: 3.1rem;
}

.ac-input.ac-textInput,
.ac-input.ac-numberInput,
.ac-input.ac-dateInput,
.ac-input.ac-timeInput,
.ac-input.ac-multichoiceInput {
    background-color: #F3F2F1;
    border: 0.1rem solid #F3F2F1;
    border-radius: 0.4rem;
    padding: 0.4rem 0.8rem 0.4rem 0.8rem;
}

/* ac-inlineActionButton should set height to the same as ac-input.ac-textInput */

.ac-inlineActionButton {
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    font-family: "Segoe UI", sans-serif;
    font-size: 1.4rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: none;
    background-color: transparent;
    height: 3.1rem;
}

.ac-inlineActionButton.textOnly {
    padding: 0 0.8rem;
}

.ac-inlineActionButton.iconOnly {
    padding: 0;
}

.ac-inlineActionButton:hover {
    background-color: #EEEEEE;
}

.ac-inlineActionButton:active {
    background-color: #CCCCCC;
}